<template>
  <div id="user-profile">
    <b-row>
      <b-col lg="12" cols="12" order="1" order-lg="1">
        <b-card class="profile-header mb-2" body-class="p-0">
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6" class="mb-md-0 mb-2">
                <label>Sınıf/Ders</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="selectedLesson"
                  :options="lessons"
                  class="w-100"
                  :getOptionLabel="
                    (option) => option.class.name + ' / ' + option.lesson.name
                  "
                  :reduce="(val) => val.id"
                />
              </b-col>

              <b-col cols="12" md="6" class="mb-md-0 mb-2">
                <label>Alt Sınıf</label>
                <v-select
                  id="altsinif"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="childClassId"
                  :options="childClass"
                  class="w-100"
                  :reduce="(val) => val.class"
                  :getOptionLabel="(option) => option.class.name"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" class="mb-md-0 mb-2">
                <b-button block variant="success" @click="getStudent()"
                  >FİLTRELE</b-button
                >
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col cols="12" md="12" class="mb-md-0 mb-2">
                <b-button block variant="primary" @click="getResultxlsx()"
                  >İNDİR</b-button
                >
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card class="profile-header mb-2" no-body>
          <b-table
            id="sinif"
            :items="users"
            responsive
            :fields="questionFields"
            class="mb-0"
          >
            <template #cell(fullName)="data">
              <div class="position-relative">
                <b-row>
                  <b-col lg="2" cols="12" order="1" order-lg="1">
                    <div class="profile-img">
                      <b-img
                        :src="data.item.profileImage"
                        style="width: 126px; height: 126px; object-fit: contain"
                        rounded
                        fluid
                        alt="profile photo"
                      />
                    </div>
                  </b-col>
                  <b-col lg="8" cols="12" order="1" order-lg="1">
                    <div class="profile-title">
                      <h2 class="text-black">
                        {{ data.item.firstName }} {{ data.item.lastName }}
                      </h2>
                    </div>
                  </b-col>

                  <b-col lg="2" cols="12" order="1" order-lg="1">
                    <b-button @click="onChangeProfileImage(data.item)">Resmi Değiştir</b-button>
                  </b-col>
                </b-row>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <input style="display: none" type="file" id="files" ref="files" v-on:change="handleFileUploads()" />
  </div>
</template>

<script>
import {
  BOverlay,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTable,
  BAvatar,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
import XLSX from "xlsx";
import { jsPDF } from "jspdf";
import Vue from "vue";
import VueHtml2Canvas from "vue-html2canvas";

Vue.use(VueHtml2Canvas);
Vue.use(require("vue-moment"));
import moment from "moment";
export default {
  components: {
    BOverlay,
    BProgressBar,
    BProgress,
    BTable,
    BAvatar,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
  },
  watch: {
    selectedLesson: function (val) {
      this.childClass = [];
      this.childClassId = null;
      if (val != null) {
        var filterData = this.lessons.filter((x) => x.id == val)[0];
        this.childClass = filterData.childClass;
      }
    },
  },
  data() {
    return {
      loading: false,
      selectedLesson: "",
      childClassId: "",
      users: [],
      lessons: [],
      childClass: [],
      questionFields: [
        { key: "fullName", label: "Öğrenci Adı Soyadı" },
        // { key: 'action', label: 'İşlem' },
      ],
      selectedUser: {},
    };
  },
  mounted() {},
  created() {
    this.getLesson();
  },
  mounted() {},
  methods: {
    async onChangeProfileImage(user) {
        this.selectedUser = user;
        this.$refs.files.click();
    },
    async handleFileUploads() {
      var file = this.$refs.files.files[0];
      if (file == null) {
        alert("Dosya Seçilmedi");
        return;
      }

      this.loading = true;
      let form = new FormData();
      form.append("file", file);
      await this.$http.put("Teacher/StudentProfileImage/"+this.selectedUser.id, form);
      this.loading = false;

      this.getStudent();

    },
    async getLesson() {
      var lesson = await this.$http.get("Teacher/Lessons");
      this.lessons = lesson.data.data;
    },
    async getStudent() {
      var users = await this.$http.get(
        "Teacher/StudentsChildClass/" + this.childClassId.id
      );
      this.users = users.data.data;
    },
    async getResultxlsx() {
      let excelItems = [];
      var index = 1;

      var data_type = "data:application/vnd.ms-excel";
      var table_header =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><meta charset="UTF-8">';
      var table_div_start =
        "<title>Sınıf Listesi</title> <table> <tbody> " +
        '<thead> <tr> <th colspan="5" width="600"> Sınıf Listesi </th> </tr> <tr> <th > Sıra </th><th>Foto</th><th>İsim</th><th>Soyisim</th><th>Sınıf</th> </tr></thead>';
      var table_div_body = "";
      var table_div_end = " </tbody> </table></html>";
      //just in case, prevent default behaviour

      this.users.forEach((x) => {
        //console.log(x);

        table_div_body =
          table_div_body +
          ' <tr height="60"> <td>' +
          index +
          '</td> <td><img width="60" height="60" src="' +
          x.profileImage +
          '" alt=""   /></td> </td> ' +
          "<td>" +
          x.firstName +
          "</td>" +
          "<td>" +
          x.lastName +
          "</td>" +
          "<td>" +
          this.childClassId.name +
          "</td>" +
          "</tr>";

        excelItems.push({
          Sıra: index,
          Foto: x.profileImage, // x.answers.filter((x) => x.isTrue == true)[0].text,
          "İsim ": x.firstName + " " + x.lastName,
        });
        index++;
      });
      window.open(
        "data:application/vnd.ms-excel," +
          encodeURIComponent(
            table_header + table_div_start + table_div_body + table_div_end
          )
      );

      /*let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
          
            await XLSX.writeFile(wb,"Sınıf Listesi" + ".xlsx");*/
    },
  },
};
</script>



 

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 